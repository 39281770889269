@font-face {
  font-family: 'spotify-circular';
  src: local('spotify-circular'), url(../fonts/CircularSpotifyTxT-Black.ttf) format('ttf');
}

html body {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'spotify-circular', Helvetica, Arial, sans-serif;
  width: auto;
  margin: auto;
  text-align: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem !important;
  margin: 0 1rem;
  margin-top: 25px;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otpInput:focus {
  outline: none !important;
}

.button {
  font-family: 'spotify-circular', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  font-size: 25px;
  border-radius: 50px !important;
  padding: 15px 120px !important;
  height: 75px;
  width: 100%;
  min-width: 400px;
}

.button:hover {
  background-color: #1ed760 !important;
}
